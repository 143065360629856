

























import ProjectNav from '@/layout/components/nav-project/index.vue';
import Sidebar from '@/layout/components/sidebar/index.vue';
import { defineComponent } from '@vue/composition-api';
import KoSnackbar from '@/layout/components/snackbar/index.vue';
import KoErrorModal from '@/layout/components/error-modal/index.vue';
import CookieConcent from '@/layout/components/cookie-consent/index.vue';
import StatusFooter from '@/components/project-footer-status/index.vue';
import FeedbackModal from '@/layout/components/feedback-modal/index.vue';
import { useGlobal } from '@/modules/global';
import {
  PROJECT_CALCULATION_PATH,
  PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
  PROJECT_CALCULATION_ENVIRONMENT,
  PROJECT_CALCULATION_PRICE_UPDATE_PATH,
} from '@/constants/routes';
import WelcomeModal from '@/layout/components/welcome-modal/index.vue';

export default defineComponent({
  name: 'AppLayoutProtected',
  components: {
    ProjectNav,
    Sidebar,
    KoSnackbar,
    CookieConcent,
    KoErrorModal,
    StatusFooter,
    FeedbackModal,
    WelcomeModal,
  },
  setup() {
    const { isOnRoute } = useGlobal();
    return {
      isOnRoute,
      PROJECT_CALCULATION_PATH,
      PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
      PROJECT_CALCULATION_ENVIRONMENT,
      PROJECT_CALCULATION_PRICE_UPDATE_PATH,
    };
  },
});
