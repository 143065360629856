



















































































































































































































































import { useCalculation } from '@/modules/calculation';
import { defineComponent } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import Profession from '@/components/profession/index.vue';
import CalculationStatus from '@/components/project-footer-status/calculation-status/index.vue';
import {
  sumMargin,
  sumTotal,
  sumProfit,
  sumHours,
  sumSelfCostMaterialsSubContractors,
} from '@/helpers/calculation';
import { useBatch } from '@/modules/batch';
import { useProject } from '@/modules/project';

export default defineComponent({
  components: {
    Loader,
    Profession,
    CalculationStatus,
  },
  setup() {
    const {
      projectCalculation,
      updating,
      saveCalculation,
      destructActiveCalculation,
      loading,
    } = useCalculation();

    const { activeProjectOverview } = useProject();
    const { resetChanges } = useBatch();

    return {
      sumMargin,
      sumTotal,
      sumProfit,
      sumHours,
      destructActiveCalculation,
      saveCalculation,
      resetChanges,
      sumSelfCostMaterialsSubContractors,
      loading,
      projectCalculation,
      updating,
      activeProjectOverview,
    };
  },
});
